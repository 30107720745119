<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="this.$route.meta.keepAlive"></router-view>
            <!--这里是会被缓存的组件-->
        </keep-alive>
        <router-view v-if="!this.$route.meta.keepAlive"></router-view>
        <AlertMessage></AlertMessage>
    </div>
</template>

<script>
import AlertMessage from "./components/AlertMessage.vue";
import { mapMutations, mapGetters } from "vuex";
import { miceService } from "@/service/miceService.js";
import { SmartStorage } from "smart-core-util";
import { getCookie } from "tiny-cookie";
export default {
    name: "App",
    components: {
        AlertMessage,
    },
    async created() {
        await this.$mealInit()
        this.getTheme();
        if (SmartStorage.get("isH5") || getCookie("tenant_id") == "1afb2744-d3c9-11eb-a9f0-ca43ba5bd41d") {
            this["ORDER_DETAIL"](SmartStorage.get("orderDetail"));
            this["EVENT"](SmartStorage.get("eventData"));
        }
        this.setTenantName()
    },
    mounted() {
        this.setEvent();
    },
    computed: {
        ...mapGetters(["event"]),
    },
    methods: {
        ...mapMutations(["EVENT", "ADDRESS", "CITY_ADDRESS", "MEMSG", "ORDER_DETAIL", "MEAL_RULES"]),
        
        getTheme() {
            let paramCode = SmartStorage.get("paramCode");
            if (paramCode) {
                //更换皮肤颜色
                let dataCode = paramCode;
                if (SmartStorage.get("tenant_code") == "dscl" || getCookie("tenant_code") == "dscl") {
                    dataCode = window.atob(dataCode);
                    dataCode = window.decodeURIComponent(dataCode);
                    dataCode = JSON.parse(dataCode);
                }
                let skin = dataCode.skin || dataCode.tenant;
                if (!skin) {
                    skin = "primary";
                }
                window.document.documentElement.setAttribute("data-theme", skin);
            } else {
                var eventData = SmartStorage.get("eventData") || {};
                window.document.documentElement.setAttribute("data-theme", eventData.tenant_code);
            }
        },
        async setEvent() {
            // 餐厅推荐进来
            if (SmartStorage.get("recommend")) {
                this.getPosition();
                return;
            }
            let dataCode = SmartStorage.get("paramCode") || {};
            let event = SmartStorage.get("eventData") || {};
            if (!event.tenant_code) {
                event.tenant_code = SmartStorage.get("tenant_code");
            }
            if (SmartStorage.get("tenant_code") == "dscl" || getCookie("tenant_code") == "dscl") {
                dataCode = window.atob(dataCode);
                dataCode = window.decodeURIComponent(dataCode);
                dataCode = JSON.parse(dataCode);
            }
            if (dataCode.search) {
                let eventData = {
                    cityDictVal: dataCode.city,
                    search: dataCode.search,
                    tenant_code: SmartStorage.get("tenant_code"),
                };
                let address = {
                    name: dataCode.city,
                    latitude: dataCode.latitude,
                    longitude: dataCode.longitude,
                    city: dataCode.city,
                };
                eventData.address = address;
                this["ADDRESS"](address);
                this["EVENT"](eventData);
                this.setTenantName()
                SmartStorage.set("eventData", JSON.stringify(eventData), {
                    domain: window.idomain,
                });
                SmartStorage.set("eventData", JSON.stringify(eventData));
                let _this = this;

                setTimeout(function () {
                    if (_this.$route.query.type == "selectShop") {
                        return;
                    }
                    _this.$router.push("/shopList?refresh=true");
                }, 300);
            } else if (!SmartStorage.get("isFromMore")) {
                if(this.$route.query.authorize){
                    return
                }
                let res = await this.getMiceInfo();
                // 兼容协会
                let eventData = null;
                if (res && res.success) {
                    let resContent = res.content || {};
                    eventData = {
                        ...event,
                        proposalId: event.miceId || dataCode.miceId || event.proposalId,
                        cityDictVal: resContent.city || resContent.cityDictVal || resContent.City || "",
                        tenantId: resContent.tenantId || resContent.TenantId || "",
                        tenant_code: dataCode.tenant || SmartStorage.get("tenant_code") || getCookie("tenant_code"),
                        dtEnd: resContent.dtEnd || resContent.DtEnd || "",
                        dtStart: resContent.dtStart || resContent.DtStart || "",
                        dtTypeTxt: resContent.dtTypeTxt || "",
                        typeDictTxt: resContent.typeDictTxt || resContent.TypeDictVal || "",
                        statusTxt: resContent.statusTxt || resContent.StatusTxt || "",
                    };
                    if(["roche",].includes(SmartStorage.get("tenant_code") || getCookie("tenant_code")) && resContent.itsExtData){
                        eventData.itsExtData = {
                            ...resContent.itsExtData,
                            ...event.itsExtData
                        }
                    }
                    if (["dscl", "pfizer", "crossevent"].includes(SmartStorage.get("tenant_code") || getCookie("tenant_code")) && !this.$route.query.recommend) {
                        let cityDetail = JSON.parse(resContent.ExtData || resContent.extData).cityDetail || "";
                        let address = {
                            name: resContent.city || resContent.cityDictVal || resContent.City,
                            latitude: cityDetail.latitude || "0",
                            longitude: cityDetail.longitude || "0",
                            city: resContent.city || resContent.cityDictVal || resContent.City,
                        };
                        this["CITY_ADDRESS"](address);
                    }
                    this["EVENT"](eventData);
                    this.setTenantName()
                    SmartStorage.set("eventData", JSON.stringify(eventData), {
                        domain: window.idomain,
                    });
                    SmartStorage.set("eventData", JSON.stringify(eventData));
                    if (this.$route.query.type == "selectShop" || this.event.mealCombineData) {
                        return;
                    }
                    // 协办会外出用餐结算不用到列表
                    if (SmartStorage.get("isH5") && SmartStorage.get("type") == "imeal") {
                        return;
                    }
                    // 企业码授权
                    if ((SmartStorage.get("authorize") || SmartStorage.get("goAlipay") || SmartStorage.get("placeOrder")) || SmartStorage.get("isFromMore")) {
                        return;
                    }
                    this.$router.push(`/orderList`);
                }
            }
            this.setTenantName();
        },
        setTenantName() {
            let event = {
                ...this.event,
            };
            switch (getCookie("tenant_id")) {
                case "256e797e-f9a2-11eb-bf5d-42a68d1dd535":
                    event.tenant_name = "会引擎-协办会";
                    break;
                case "1afb2744-d3c9-11eb-a9f0-ca43ba5bd41d":
                    event.tenant_name = "会引擎-huarun";
                    break;
                case "8fad140e-67b4-11ec-b6d3-2a03e8deecca":
                    event.tenant_name = "会引擎-疾控";
                    break;
                case "e7a9bfbd-e5fc-11eb-a12a-d2358b3d1d59":
                    event.tenant_name = "会引擎-协会";
                    break;
                case "7faf5393-8a70-11ec-89d2-6a0616dfdee4":
                case "ee6ea2ae-f2c7-11ec-a826-be1305a58be3": // 广阔市场（石药部门）
                case "207d3942-395e-11ed-90ea-863f87f6346f": // 石药药品
                    event.tenant_name = "会引擎-石药";
                    break;
                case "d1b74701-cd07-11ec-afb5-6a9b383694d2":// 石药集团
                    event.tenant_name = "会引擎-恩必普";
                    break;
                case "51499834-da35-11ec-bdf1-0a1c87dc4eec":
                    event.tenant_name = "会引擎-科伦";
                    break;
                case "c3861376-4eb6-11ed-9bb4-22cc57c8ec85":
                    event.tenant_name = "会引擎-灵北";
                    break;
                default:
                    event.tenant_name = "会引擎";
            }
            this["EVENT"](event);
            console.log('=-=-=-event',this.event);
        },
        getMiceInfo() {
            return new Promise((resolve) => {
                let dataCode = SmartStorage.get("paramCode") || {};
                if (SmartStorage.get("tenant_code") == "dscl" || getCookie("tenant_code") == "dscl") {
                    dataCode = window.atob(dataCode);
                    dataCode = window.decodeURIComponent(dataCode);
                    dataCode = JSON.parse(dataCode);
                }
                let event = SmartStorage.get("eventData");

                if (SmartStorage.get("tenant_code") == "crossevent") {
                    let params = { projection: { _id: 0 }, sort: {}, filter: { MiceId: dataCode.miceId || event.miceId || event.proposalId } };
                    miceService.GetEventOrder(params).then((resData) => {
                        resolve(resData);
                    });
                } else {
                    let params = {
                        id: dataCode.miceId || event.miceId,
                    };
                    miceService.getMiceInfo(params, SmartStorage.get("tenant_code")).then((resData) => {
                        resolve(resData);
                    });
                }
            });
        },
        getPosition() {
            let _this = this;
            var map = new AMap.Map("container", {});
            AMap.plugin("AMap.Geolocation", () => {
                /* global AMap */
                var geolocation = new AMap.Geolocation({
                    timeout: 3000, //超过n秒后停止定位，默认：5s
                    useNative: true,
                    enableHighAccuracy: true, //是否使用高精度定位，默认:true
                    extensions: "all",
                });
                map.addControl(geolocation);
                geolocation.getCurrentPosition((status, result) => {
                    console.log("====getCurrentPosition====", status, result);
                    if (status == "complete") {
                        let city = result.addressComponent.city || result.addressComponent.province;
                        let address = {
                            latitude: result.position.lat,
                            longitude: result.position.lng,
                            name: (result.pois || [{ name: "" }])[0].name || result.formattedAddress,
                            city: city,
                        };
                        if(this.event.tenant_code == "roche" &&(this.event.typeDictTxt == "科室会" ||this.event.typeDictTxt == "院内会")){
                            address = {
                                latitude: this.event.nl || result.position.lat,
                                longitude: this.event.el || result.position.lng,
                                name: this.event.hospitalName || (result.pois || [{ name: "" }])[0].name || result.formattedAddress,
                                city: city,
                            }
                        }
                        _this["ADDRESS"](address);
                    }
                });
            });
        },
    },
};
</script>

<style lang="scss">
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0.14rem;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}
html {
    background: #fff;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
}
</style>
